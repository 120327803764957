import PropTypes from 'prop-types';
import React from 'react';


import IconCash from '../../../images/icons/icon-cash.svg';
import IconDelivery from '../../../images/icons/icon-delivery.svg';
import IconHome from '../../../images/icons/icon-home.svg';
import IconLike from '../../../images/icons/icon-like.svg';
import IconMobile2 from '../../../images/icons/icon-mobile2.svg';
import IconSites from '../../../images/icons/icon-sites.svg';
import IconSmileFace from '../../../images/icons/icon-smile-face.svg';
import IconHearth from '../../../images/icons/icon-hearth.svg';

class SectionRequirements extends React.Component {

    render() {

        return (
            <>
                <div className="layout--4-column-split">
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconHearth className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                18 Years Old+
                                </h5>
                                <p>
                                You must be at least 18 years old &amp; a US citizen or permanent resident
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconMobile2 className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                Mobile Phone
                                </h5>
                                <p>
                                To contact you about your request, your phone number is need
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconHome className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                Your Current Address
                                </h5>
                                <p>
                                Doesn’t matter if you rent or own your home
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconCash className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                Checking Account
                                </h5>
                                <p>
                                Get your funds deposited directly into your bank account by a lender
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
                <div className="layout--4-column-split">
                    
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconSites className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                A Valid ID
                                </h5>
                                <p>
                                A state issued driver’s license or ID is needed
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconSmileFace className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                Social Security Number
                                </h5>
                                <p>
                                To verify your identity, your SSN is required
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconDelivery className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                A Source of Income
                                </h5>
                                <p>
                                You will need a recurring source of income; i.e. employment, self-employed or benefits
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconLike className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                Worried About Your Credit?
                                </h5>
                                <p>
                                There’s no need to worry. CashHappens™ welcomes all credit types!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

SectionRequirements.propTypes = {
    hexagon: PropTypes.bool,
};


export default SectionRequirements;