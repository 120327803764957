import React from 'react';

import {
    Expander,
    ExpanderItem,
} from '@brandedholdings/react-components';
import { siteMeta } from '../../../data/site_meta.yml';



export default class SectionFAQs extends React.Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    
    render() {
        
        return (
            <Expander altStyle={false}>
                <ExpanderItem title="Can I find a lender if I have bad credit?">
                    <p>
                        <strong>Yes, you can!</strong>
                        {' '}
                        {siteMeta.nameShort} works with a variety of lenders, many who offer options for people with all types of credit.                        
                    </p>
                </ExpanderItem>
                <ExpanderItem title="What is the interest rate? When do payments begin?">
                    <p>
                        Once you find a lender with {siteMeta.nameShort}, your lender will present the terms &amp; conditions of their loan offer to you. This will include the interest rate, dates for repayment, finance charges, etc. 
                    </p>
                    <p>
                        Please remember, every lender is different, each offers a variety of financial solutions depending on your unique situation. 
                    </p>
                    <p>
                        You can choose whether or not you want to accept the loan offer presented to you, you can decline the offer &amp; resubmit to find a new lender.
                    </p>
                </ExpanderItem>
                <ExpanderItem title="How will I know if a lender wants to give me an offer?">
                    <p>
                        Once you submit your information using {siteMeta.nameShort} easy and secure online form, lenders will review your request to determine what financial options they would like to offer you. 
                    </p>
                    <p>
                        When a lender is ready to make you an offer, you will be redirected to their website, where you can review their offer.
                    </p>
                </ExpanderItem>
                <ExpanderItem title="Are there any restrictions on what I can use my loan for?">
                    <p>
                        <strong>No</strong>, it’s none of our business what you need your loan for.
                    </p>
                </ExpanderItem>
                <ExpanderItem title="Is it possible to find a lender today?">
                    <p>
                    <strong>YES</strong>
                    , You could find a lender in a matter of minutes! Simply fill out our easy and secure online form.  Once you have submitted your information, lenders will review your request to determine what financial options they would like to offer you.
                    </p>
                </ExpanderItem>
                <ExpanderItem title="My income is fixed. Will I still qualify?">
                    <p>
                        <strong>
                        YES!
                        </strong>
                        {' '}
                        {siteMeta.nameShort} working with a variety of lenders who specialize in providing loans to people who receive benefits such as Social Security, Retirement, or Veteran's Benefits.
                    </p>
                </ExpanderItem>
                <ExpanderItem title={"How much does it cost to use " + siteMeta.nameShort + "?"}>
                    <p>
                        <strong>NOTHING!</strong>
                        {' '}
                        {siteMeta.nameShort} is
                        {' '}
                        <strong>FREE for anyone</strong>
                        {' '}
                        to use.
                    </p>
                </ExpanderItem>
                <ExpanderItem title="I receive multiple forms of income; I am employed and receive benefits. Which form of income should I use?">
                    <p>
                        Please select the form of income that represents the largest sum of your monthly income.
                    </p>
                </ExpanderItem>
                <ExpanderItem title={"How does " + siteMeta.nameShort + "  protect and secure my personal data?"}>
                    <p>
                        The information you provide is protected with AES256 bit encryption.
                    </p>
                </ExpanderItem>
            </Expander>
        );
    }
};
